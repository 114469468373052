import React, { useEffect, useState } from "react";
import Layout from "components/layout";
import Seo from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { TopicDetectionToolFaqData } from "components/FAQ/config";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Tooltip } from "react-tooltip";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import { useLocation, } from "@reach/router";
import Loader from "components/loader";
import CopyIcon from "assets/copytools.jpg";
import { navigate } from "gatsby";

const Result = () => {
  const handleCopy = () => {
    const textToCopy = document.getElementById("output").innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopySuccess("Copied");
    setTimeout(() => {
      setCopySuccess("Copy");
    }, 1500);
  };
  const [wordCount, setWordCount] = useState(0);
  const [pageLoader, setPageLoader] = useState(true);
  const [detectedTopicText, setDetectedTopicText] = useState("");
  const [sourceText, setSourceText] = useState("");
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy");
    const [inputLang, setInputLang] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const shareId = params.get("share-id");

  useEffect(() => {
    const fetchAnalysis = async () => {
      try {
        const res = await http().get(`${endpoints.forms.aiTools}/${shareId}`);
        const response = res.result;

        const { user_text: sourceText,source_language_code:sourceCode, } = res.meta;
        setInputLang(sourceCode)
        let parsedResponse;
        if (typeof response === "string") {
          parsedResponse = JSON.parse(response.replace(/,\s*}/, "}"));
        } else {
          parsedResponse = response;
        }

      
        const domain = parsedResponse?.domain || parsedResponse?.Domain;
        const specialization =
          parsedResponse?.Specialization || parsedResponse?.specialization;

        if (domain && specialization) {
          setDetectedTopicText(`${domain} (${specialization})`);
        } else if (domain) {
          setDetectedTopicText(domain);
        } else {
          setDetectedTopicText("");
        }

        setWordCount(
          sourceText
            ? sourceText.split(/\s+/).filter((word) => word.length > 0).length
            : 0
        );
        setSourceText(sourceText);
        // reset({ message: sourceText || "" });
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setPageLoader(false);
      }
    };

    fetchAnalysis();
  }, []);

  if (pageLoader)
    return (
      <Layout>
        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>
      </Layout>
    );

  return (
    <Layout>
      <Seo
        title="Topic Detection Tool"
        description="Our topic identification tool will detect the main topic or subject field of your text, ensuring it's matched with a translator with the right expertise."
        slug="/tools/topic-detection"
      />

      <div className="bg-[#F5F5F5] ">
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>

        <div className="py-12 md:py-24 max-w-7xl mx-auto px-4 grid lg:grid-cols-2 gap-12">
          <div>
            <h1 className="text-[#5B93FF] text-center md:text-start text-base font-primary font-semibold uppercase">
              TOPIC DETECTION TOOL
            </h1>
            <h2 className="text-[#0A2641] pt-3 text-center md:text-start font-bold text-[28px] lg:text-[40px] leading-[48px] font-primary">
              Match Texts with Specialized Translators for Enhanced Quality
            </h2>
            <p className="hidden md:block text-[#424242] text-center md:text-start text-base md:text-lg font-opensans leading-[26px] pt-6">
              Enter your text in any language, and the topic identifier tool
              will automatically detect the main domain, whether it is legal,
              medical, marketing, or any other subject field.
            </p>
          </div>
          <div>
            <form action="" className="relative shadow-lg rounded-lg bg-white">
              <textarea
                dir={inputLang === "he" || inputLang === "ar" ? "rtl" : "ltr"}
                placeholder="Type your text here"
                name="message"
                id="message"
                value={sourceText}
                dangerouslySetInnerHTML={{__html: sourceText.replace(/\n/g, "<br />")}}
                className={`anonymize_tool  ${inputLang === "he" || inputLang === "ar" ? "pl-[40px] pr-5":"pl-5 pr-[40px]"} py-5 min-h-[240px] placeholder-[#B4B4B4] text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block w-full`}
                defaultValue={sourceText}
              />

              {wordCount > 0 && (
                <p
                  classNa className={`absolute ${inputLang === "he" || inputLang === "ar" ? "left-[17px]" : "right-[17px]"} top-[14px]  w-max cursor-pointer`}
                  onClick={() => navigate(`/tools/topic-detection`)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M19.5787 17.5581C19.8469 17.8263 19.9977 18.1902 19.9977 18.5696C19.9977 18.9489 19.8469 19.3128 19.5787 19.581C19.3104 19.8493 18.9466 20 18.5672 20C18.1878 20 17.824 19.8493 17.5557 19.581L10.0006 12.0235L2.44313 19.5787C2.17487 19.8469 1.81104 19.9976 1.43166 19.9976C1.05228 19.9976 0.688446 19.8469 0.420187 19.5787C0.151927 19.3104 0.00122071 18.9466 0.0012207 18.5672C0.0012207 18.1878 0.151927 17.824 0.420187 17.5557L7.97768 10.0006L0.422566 2.4431C0.154307 2.17484 0.00360069 1.81101 0.0036007 1.43163C0.0036007 1.05225 0.154307 0.688415 0.422566 0.420156C0.690826 0.151897 1.05466 0.00118977 1.43404 0.00118977C1.81342 0.00118977 2.17725 0.151897 2.44551 0.420156L10.0006 7.97765L17.5581 0.418966C17.8264 0.150706 18.1902 -6.32041e-09 18.5696 0C18.949 6.32041e-09 19.3128 0.150706 19.5811 0.418966C19.8493 0.687225 20 1.05106 20 1.43044C20 1.80982 19.8493 2.17365 19.5811 2.44191L12.0236 10.0006L19.5787 17.5581Z"
                      fill="#69AAE8"
                    />
                  </svg>
                  {/* &#10005; */}
                </p>
              )}
              <div className="flex justify-between items-center p-4">
                <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                  word count: {wordCount}
                </p>
                <button
                  type="submit"
                  disabled={!!detectedTopicText}
                  className={` ${
                    detectedTopicText
                      ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                      : "bg-lightBlue text-white"
                  } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-2`}
                >
                  Identify topic
                </button>
              </div>
            </form>
            <div className="mt-4 rounded-lg bg-white w-full md:flex justify-between items-center px-4 py-6 shadow-lg">
              <p className="text-[#858585] font-opensans text-semibold leading-6">
                Domain:
              </p>
              <p className="text-[#858585] mt-4 md:mt-0 font-opensans text-semibold leading-6">
                {detectedTopicText ? (
                  <>
                    <p className="flex items-center gap-2 w-max">
                      <span
                        id="output"
                        className="text-[#27862A] text-lg font-bold leading-6 font-opensans"
                      >
                        {detectedTopicText}
                      </span>
                      <button
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content={copySuccess}
                        onClick={handleCopy}
                        className=""
                      >
                        <img src={CopyIcon} alt="/copyicons" />
                      </button>
                    </p>
                    <Tooltip
                      id="copy-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                  </>
                ) : error ? (
                  <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                    {error.message}
                  </span>
                ) : (
                  "--"
                )}
              </p>
            </div>
            <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
              Assisted by AI tools. Inaccuracies may occur.
            </p>
          </div>
          <p className="block md:hidden text-[#424242] text-center md:text-start text-base md:text-lg font-opensans leading-[26px] mt-8">
            Enter your text in any language, and the topic identifier tool will
            automatically detect the main domain, whether it is legal, medical,
            marketing, or any other subject field.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={TopicDetectionToolFaqData} />
    </Layout>
  );
};

export default Result;
